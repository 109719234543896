.container {
    text-align: left;
    max-width: 800px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.row-col-auto {
    flex:1;
}

.flex-row-even {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.event-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.event-button {
    width: 160px;
    height: 60px;
    margin: 10px;
    margin-left: 0px;
}

.action-button {
    width: 140px;
    height: 45px;
}

.title-row {
    margin-top: 20px;
}

.row-seperator {
    height: 10px;
}

.col-seperator {
    width: 10px;
}
